import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav, Row} from 'react-bootstrap';
import { Link } from "gatsby"
import SEO from "../../components/seo"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'
import ReportData from "../../assests/images/report_data.jpg";

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Custom_Presentation"; 

import {config} from '../../components/Common/constant';
import CampaignReport from '../../components/modules/campaign_report';


import { useContainerContext } from "../../store/ContainerContext";

import { GET_ARTICLE } from "../../queries/common_use_query";

import { isBrowser} from "../../services/auth"

const PresentationPage = () => {
    const context = useContainerContext();
    const { property_data } = context.state;

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('campaign-report-data');
    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    return <>
        <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
        <div className="pre-wrapper">

        <Header />

        {(isBrowser() && property_data) && <CampaignReport property_data={property_data} /> }

        <NavLink tab_name="Marketing" prevLink={property_data && parseInt(property_data.price_expectancy) > 200000 ? config.custom_plan : config.custom_pho_datetime} nextLink={config.custom_comparables} />

    </div>
    // pre-wrapper
    </>
}

export default PresentationPage