import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav, Row} from 'react-bootstrap';
import { Link } from "gatsby"
import dateFormat from 'dateformat';
import Chart from 'react-apexcharts'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'

import mapCardImg from "../../assests/images/gallery1.jpg";
import mapRgtImg from "../../assests/images/map_rgt_img.jpg";

import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";
import {useWindowDimensions} from "../../components/utils/common"


import { useContainerContext } from "../../store/ContainerContext";

import Title_Block from  "./title_block"; 



const CampaignReport = (props) => {
    var strapi_url = process.env.GATSBY_STRAPI_SRC;
    const { height, width } = useWindowDimensions();

    var chart_height = width < 768 ? 300 : 450;

    const context = useContainerContext();
    const [ property_data, setPropertyData ] = useState(props.property_data);

    const [ options, setOptions ] = useState({});
    const [ series, setSeries ] = useState([]);

    const [ article_data, setArticleData ] = useState('');
    const [ selectedDate, setSelectedDate ] = useState('');

    const { loading, error, data } = GET_ARTICLE('campaign-report-data');
    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    useEffect(()=>{
      campaignReportData(property_data, '');
    }, [property_data])

    function campaignReportData(property_data, filter_month){

      var date_xaxis = [], category_yaxis=[], right_move_stats=[], preston_bakers_stats=[];
      var today = new Date();
      var current_month_year = filter_month ? filter_month : today.getFullYear()+'-'+(today.getMonth()+1);

      setSelectedDate(current_month_year);

      //console.log("campaign_report_datas", property_data.campaign_report_datas);

      property_data.campaign_report_datas && property_data.campaign_report_datas.length > 0 && (

        property_data.campaign_report_datas.map((item, index) => {
          // if(dateFormat(item.Dates, "yyyy-m") === current_month_year){
          //   date_xaxis.push(dateFormat(item.Dates, "dd mmm"));
          //   right_move_stats.push(item.RightMoveStats);
          //   preston_bakers_stats.push(item.PrestonBakersStats);
          // } else{

          // }
          date_xaxis.push("Day "+(parseInt(index)+1));
          right_move_stats.push(item.RightMoveStats);
          preston_bakers_stats.push(item.PrestonBakersStats);
        })
      )

      category_yaxis = [{name: 'Traditional Estate Agency Marketing', data:right_move_stats}, {name: 'Preston Baker Marketing', data:preston_bakers_stats}];

      if(date_xaxis.length > 0){
        setOptions({
          chart: {
            id: 'apexchart-example',
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            categories: date_xaxis,
          },
          yaxis: {
            axisBorder: {
              show: true,
              color: '#E8E8E8',
              offsetX: 0,
              offsetY: 0
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded',
            },
          },
          colors: ['#293888', '#CC0133'],
          fill: {
            opacity: 1            
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 4,
            colors: ['transparent']
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        });
      }
      
      if(category_yaxis.length > 0){
        setSeries(category_yaxis)
      }
    }

    

    var campaign_report_data = property_data.campaign_report_datas && property_data.campaign_report_datas.length > 0 ? property_data.campaign_report_datas : [];

    const filter_map = new Map();

    return <>
        <div className="section-wrapper">
          <Container>

            <Title_Block Title={article_data && article_data.Title} Type={props.Type} PreviousLink={''} NextLink={''} NextPage={props.NextPage} PrevPage={props.PrevPage} instruct={false} />

            {article_data && article_data.Content && <p className="fig-content mb_cnt font_20">{ HTMLReactParser(article_data.Content)}</p>}

            <Row className="report_data">
              <div className="col-md-12">
                
                <div className="campain_report">
                    {
                      campaign_report_data.length > 1 && (
                        <div className="chart_filter">
                          <h3>Campaign Report Data</h3>
                          {/* <select name="filter_by_month" onChange={(e) => campaignReportData(property_data, e.target.value)}>
                            <option value="">Filter by Month</option>
                            {
                              campaign_report_data.map((item,index) => {
                                if(!filter_map.has(dateFormat(item.Dates, "yyyy-m"))){
                                  filter_map.set(dateFormat(item.Dates, "yyyy-m"), true);
                                  return(
                                    <>
                                      <option value={dateFormat(item.Dates, "yyyy-m")} selected={dateFormat(item.Dates, "yyyy-m") === selectedDate ? true : false}>{dateFormat(item.Dates, "mmmm, yyyy")}</option>
                                    </>
                                  )
                                }
                              })
                            }
                            
                          </select> */}
                        </div>
                      )
                    }
                    
                    {
                      campaign_report_data.length > 0 && (
                        <>
                          <div className="col-md-12 p-0">
                          <div className="col-md-6 float-left col-xs-12 col-sm-12">
                            <h4>{campaign_report_data[0].ListingTitle}</h4>
                            <div className="chart_count"><div className="count_left">Readers:</div> <span>{campaign_report_data.length > 0 && campaign_report_data[0].CCTReaders}</span></div>
                            <div className="chart_count"><div className="count_left">Total Exposure Time:</div> <span>{campaign_report_data.length > 0 && campaign_report_data[0].CCTTotalExposureTime.replace(" h ", "h:").replace(" min ", "m:").replace(" sec", "s")}</span></div>
                            <div className="chart_count"><div className="count_left">Average TimeOn Page:</div> <span>{campaign_report_data.length > 0 && campaign_report_data[0].CCTAverageTimeOnPage.replace(" h ", "h:").replace(" min ", "m:").replace(" sec", "s")}</span></div>
                          </div>

                          <div className="col-md-6 float-right col-xs-12 col-sm-12">
                              <img src={campaign_report_data[0].ListingPhoto} className="campaign_img" alt="" title="" />
                          </div>
                          </div>
                        </>
                      )
                    }
                    
                    
                    <Chart options={options} series={series} type="bar" width={'100%'} height={chart_height} className="chart_cls" />
                    
                </div>
                
                {/* <br />
                <figure className="digitalDomn">
                  { article_data && article_data.Image && <img src={strapi_url+''+article_data.Image.url} alt="" /> }
                </figure> */}
              </div>              

            </Row>

            
            

          </Container>
        </div>

    </>
}

export default CampaignReport